<template>
  <div>
    <eden-page-header :title="'Menu'">
      <template slot="actions">
        <div class="page--head-actions">
          <el-button
            type="primary"
            @click="goToAddMeal"
            v-if="
              allowAccessFor(['superadmin', 'admin', 'operations', 'kitchen'])
            "
          >
            Add meal item
          </el-button>
        </div>
      </template>
    </eden-page-header>
    <el-row type="flex">
      <el-col :span="24">
        <eden-page-stats
          class="my-0"
          :loading="summary.loading"
          :title="'Overview'"
          :stats="stats"
          :show-periods="true"
          :periods="periods"
          @set-period="getMenuSummary"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <eden-table-actions :title="title" @search="setQuery">
          <template slot="title"> </template>
          <template slot="actions">
            <el-row type="flex" justify="end" align="center" :gutter="10">
              <el-col :span="8">
                <div class="is-flex py-2 is-align-center">
                  <div class="is-inline mr-1 font-base">Toggle Cut Off</div>
                  <el-switch
                    :disabled="loading || !mealItems.length"
                    v-model="cutOffVal"
                    :active-value="'on'"
                    :inactive-value="'off'"
                    @change="toggleCutoff"
                  >
                  </el-switch>
                </div>
              </el-col>
              <el-col :span="6">
                <el-select
                  :disabled="loading || !mealItems.length"
                  v-model="categoryFilter"
                  value-key="id"
                  placeholder="Select Category"
                  @change="filterCategory"
                >
                  <el-option
                    v-for="(category, idx) in categories"
                    :key="idx"
                    :label="category.label"
                    :value="category.value"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <template>
                  <el-button
                    type="plain"
                    icon="eden-icon-upload"
                    class="ml-10"
                    :disabled="loading || !mealItems.length"
                    @click="exporting = true"
                  >
                    Export
                  </el-button>
                  <meal-items-export
                    :show.sync="exporting"
                    :mealItems="mealItems"
                  />
                </template>
              </el-col>
            </el-row>
          </template>
        </eden-table-actions>
      </el-col>

      <el-col :span="24">
        <eden-loader v-if="loading" />
        <template v-else>
          <eden-filter-items
            v-if="filterParams.status"
            :params="filterParams.paramsLabel"
            @clear-filter="clearFilter"
            @clear-filters="clearFilters"
          />
          <template v-if="mealItems.length">
            <el-table
              v-if="mealItems.length"
              :data="mealItems"
              @select-all="setSelectedMealItems"
              @selection-change="setSelectedMealItems"
            >
              <el-table-column type="selection" width="45"></el-table-column>
              <el-table-column>
                <template #header>
                  <eden-table-column-header
                    :label="'Meals'"
                    :property="'name'"
                    :sort-property="sort.property"
                    @sort="sortPageData($event, 'name')"
                  />
                </template>
                <template v-slot="scope">
                  <div class="is-flex is-align-center">
                    <div class="eden-meal--image">
                      <img
                        v-if="scope.row.image_url"
                        :src="getThumbnailFromCloudinary(scope.row.image_url)"
                        alt="Image"
                      />
                    </div>
                    <div class="font-sm">{{ scope.row.name }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="300">
                <template #header>
                  <eden-table-column-header
                    :label="'Category'"
                    :property="'category'"
                    :sort-property="sort.property"
                    @sort="sortPageData($event, 'category')"
                  />
                </template>
                <template v-slot="scope">
                  <span class="font-sm"> {{ scope.row.category }}</span>
                </template>
              </el-table-column>
              <el-table-column width="120">
                <template #header>
                  <eden-table-column-header
                    :label="'Price of item'"
                    :property="'price'"
                    :sort-property="sort.property"
                    @sort="sortPageData($event, 'price')"
                  />
                </template>
                <template v-slot="scope">
                  <span class="font-sm">
                    {{ formatPrice(scope.row.price) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column>
                <template #header>
                  <eden-table-column-header
                    :label="'Available Quantity'"
                    :property="'quantity'"
                    :sort-property="sort.property"
                    @sort="sortPageData($event, 'quantity')"
                  />
                </template>
                <template v-slot="scope">
                  <span class="font-sm">
                    {{ scope.row.quantity }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="allowAccessFor(['admin', 'operations'])"
                width="80"
              >
                <template v-slot="scope">
                  <meal-item-actions
                    :meal-item="scope.row"
                    @update="getMealItems"
                  />
                </template>
              </el-table-column>
            </el-table>
            <eden-pagination
              v-if="showPagination"
              :from="pagination.from"
              :to="pagination.to"
              :total="pagination.total"
              :current-page.sync="page"
            />
          </template>
          <eden-content-empty v-else :text="'No meal items'" />
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import MealItemActions from "@/components/DanceFestival/Menu/MealItemActions.vue";
import mealItems from "@/requests/dance-festival/menu.js";
import { mapMealItemsFromBackend } from "@/helpers/api-mappers/dance-festival/menu.js";
import cutoff from "@/requests/dance-festival/cut-off.js";
import MealItemsExport from "@/components/DanceFestival/Menu/MealItemsExport.vue";

export default {
  components: {
    MealItemActions,
    MealItemsExport,
  },
  data() {
    return {
      loading: false,
      exporting: false,
      cutOffVal: "off",
      mealItems: [],
      period: "today",
      periods: {
        today: "Today",
        lastweek: "Last week",
        thismonth: "This month",
      },
      showPagination: true,
      page: 1,
      pagination: {
        from: null,
        to: null,
        total: null,
      },
      sort: {
        property: "name",
        direction: "asc",
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      categoryFilter: "All",
      categories: [
        { label: "Brunch", value: "brunch" },
        { label: "Lunch", value: "lunch" },
        { label: "Dinner", value: "dinner" },
        { label: "All", value: "all" },
      ],
      summary: {
        loading: false,
        data: {},
      },
    };
  },
  computed: {
    stats() {
      const summary = this.summary.data;
      return [
        {
          label: "Total Meal items",
          figure: this.formatFigure(summary.total),
        },
        {
          label: "Brunch meal items",
          figure: this.formatFigure(summary.brunch),
        },
        {
          label: "Lunch meal items",
          figure: this.formatFigure(summary.lunch),
        },
        {
          label: "Dinner meal items",
          figure: this.formatFigure(summary.dinner),
        },
      ];
    },
    title() {
      return this.mealItems.length + " Meal items";
    },
  },
  created() {
    this.getCutOff();
    this.getMenuSummary({ period: this.period });
    this.getMealItems();
  },
  watch: {
    page() {
      this.getMealItems();
    },
  },

  methods: {
    getMenuSummary({ period, from, to }) {
      this.summary.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      mealItems
        .summary(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.summary.data = data;
            this.period = period;
          }
          this.summary.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    goToAddMeal() {
      this.$router.push({ name: "dance-festival.df-menu.add" });
    },
    toggleCutoff(val) {
      this.loading = true;
      cutoff
        .update({ value: val })
        .then(({ data }) => {
          const { status, message } = data;
          if (status) {
            this.$message.success(message);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getCutOff() {
      cutoff
        .get()
        .then(({ data }) => {
          this.cutOffVal = data.data;
        })
        .catch(() => {});
    },
    getMealItems() {
      const stringedParams =
        `?is_for_crm=true&page=${this.page}&` +
        this.sortObjectAsParams(this.filterParams.params);

      this.loading = true;
      mealItems
        .list(stringedParams)
        .then(({ data }) => {
          this.mealItems = mapMealItemsFromBackend(data.data.data);
          this.pagination.from = data.data.from;
          this.pagination.to = data.data.to;
          this.pagination.total = data.data.total;
          this.pagination.pageSize = data.data.per_page;
          this.showPagination = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setQuery(query) {
      if (query === "") {
        this.getMealItems();
        return;
      }

      this.loading = true;
      mealItems
        .search(query)
        .then(({ data }) => {
          this.mealItems = mapMealItemsFromBackend(data.data.data);
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setSelectedMealItems() {},
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.mealItems = this.sortList(this.mealItems, property, direction);
    },
    filter({ params, paramsLabel }) {
      console.log({ params, paramsLabel });
      const stringedParams = "?" + this.sortObjectAsParams(params) + "&";
      this.page = 1;
      this.filterParams = {
        status: true,
        params: { ...this.filterParams.params, ...params },
        paramsLabel: { ...this.filterParams.paramsLabel, ...paramsLabel },
      };
      this.getMealItems(stringedParams);
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;
      if (Object.keys(keys).length > 1) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.getMealItems();
    },
    filterCategory(filter) {
      if (filter) {
        this.filter({
          params: { ...this.filterParams.params, filter },
          paramsLabel: {
            ...this.filterParams.paramsLabel,
            order_type: {
              key: "filter",
              label: "Cateogry",
              type: "list",
              options: this.categories,
              value: [filter],
            },
          },
        });
      } else {
        this.clearFilter("filter");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  &:not(:last-child) {
    margin-bottom: 50px !important;
  }
}

.eden-meal--image {
  height: 36px !important;
  width: 36px !important;
  min-height: 36px;
  min-width: 36px;
  border-radius: 50%;
  background: #798b83;
  overflow: hidden;

  margin-right: 20px;

  img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
    background: #798b83;
  }
}
</style>
