import axios from "axios";

export default {
  get() {
    return axios.get("festival/menu/cutoff");
  },

  update(payload) {
    return axios.post(`festival/menu/cutoff/`, payload);
  },
};
