<template>
  <div>
    <el-dropdown @command="command">
      <slot name="actions-trigger">
        <span class="el-dropdown-link more">
          <i class="eden-icon-more"></i>
        </span>
      </slot>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :icon="'eden-icon-edit'" :command="'form'"
          >Edit meal item</el-dropdown-item
        >
        <el-dropdown-item :icon="'eden-icon-delete'" :command="'delete'"
          >Remove meal item</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <eden-confirm-dialog
      title="Remove meal item?"
      button-text="Yes, remove"
      button-type="danger"
      :show.sync="action.visibility"
      @confirm="deleteItem"
      :button-status="deleting"
    >
      Are you sure you want to remove this meal item from the menu?
    </eden-confirm-dialog>
    <!-- <component
      :is="action.component"
      :action="'edit'"
      :show.sync="action.visibility"
      :mealItem="mealItem"
      @success="emitUpdate"
    /> -->
  </div>
</template>

<script>
import mealItems from "@/requests/dance-festival/menu.js";

export default {
  name: "MealItemActions",
  props: {
    mealItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      action: {
        component: "",
        command: "",
        visibility: false,
      },
      deleting: false,
    };
  },
  computed: {
    componentVisibility() {
      return this.action.visibility;
    },
  },
  watch: {
    componentVisibility() {
      if (!this.action.visibility) {
        this.action.component = "";
      }
    },
  },
  methods: {
    command(command) {
      if (command === "form") {
        this.$router.push({
          name: "dance-festival.df-menu.edit",
          params: {
            id: this.mealItem.id,
          },
        });
      } else if (command === "delete") {
        this.action.visibility = true;
      }
    },
    emitUpdate() {
      this.$emit(
        "update",
        this.action.command === "form" ? "edit" : this.action.command,
      );
    },
    deleteItem() {
      this.deleting = true;
      mealItems
        .remove([this.mealItem.id])
        .then(({ data }) => {
          if (data.status) {
            this.action.visibility = false;
            this.$message.success(data.message);
            this.emitUpdate();
          }
          this.deleting = false;
        })
        .catch((error) => {
          this.deleting = false;
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
  },
};
</script>
