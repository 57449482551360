const getCategory = (id) => {
  if (id === 1) return "Brunch";
  else if (id === 2) return "Lunch";
  else if (id === 3) return "Dinner";
  else return "-";
};

export const mapMealItemsFromBackend = (data) => {
  return data?.map((item) => ({
    id: item.id,
    name: item.full_name,
    price: item.price,
    quantity: item.available_quantity,
    category_id: item.category_id,
    image_url: item.image_url,
    category: getCategory(item.category_id),
    created_at: item.created_at,
  }));
};

export const mapMealItemFromBackend = (data) => {
  return {
    id: data.id,
    name: data.full_name,
    price: data.price,
    quantity: data.available_quantity,
    category_id: data.category_id,
    image_url: data.image_url,
    category: getCategory(data.category_id),
    created_at: data.created_at,
  };
};
